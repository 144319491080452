import React from 'react'

import PropTypes from 'prop-types'

import './feature-card.css'

const FeatureCard = (props) => {
  return (
    <div className={`feature-card-container ${props.rootClassName} `}>
      <svg viewBox="0 0 1024 1024" className="feature-card-icon">
        <path
          d="M785.664 454.656c-33.323-33.323-87.339-33.323-120.661 0l-67.669 67.669v-308.992c0-47.147-38.229-85.333-85.333-85.333-47.147 0-85.333 38.187-85.333 85.333v308.992l-67.669-67.669c-33.323-33.323-87.339-33.323-120.661 0s-33.323 87.339 0 120.661l273.664 273.664 273.664-273.664c33.323-33.323 33.323-87.296 0-120.661z"
          className=""
        ></path>
      </svg>
      <h5 className="feature-card-text Headline5">{props.cardTitle}</h5>
      <span className="feature-card-text1">{props.text}</span>
    </div>
  )
}

FeatureCard.defaultProps = {
  rootClassName: '',
  cardTitle: 'Title',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.',
}

FeatureCard.propTypes = {
  rootClassName: PropTypes.string,
  cardTitle: PropTypes.string,
  text: PropTypes.string,
}

export default FeatureCard
