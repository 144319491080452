import React from 'react'

import { Helmet } from 'react-helmet'

import FeatureCard from '../components/feature-card'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>NZ Game Studio</title>
        <meta
          name="description"
          content="Wishlist Second Coat by NZ Game Studio on Steam"
        />
        <meta property="og:title" content="NZ Game Studio" />
        <meta
          property="og:description"
          content="Wishlist Second Coat by NZ Game Studio on Steam"
        />
      </Helmet>
      <div data-role="Header" className="home-header-container">
        <header className="home-header">
          <div className="home-logo">
            <img
              alt="image"
              src="/microsoftforstartups-200h.png"
              className="home-image"
            />
          </div>
          <div className="home-menu">
            <a href="#roadmap" className="home-link">
              <span className="home-text">Roadmap</span>
              <br></br>
            </a>
            <a href="#aboutus" id="aboutus1" className="home-link1">
              About Us
            </a>
            <a href="#contactus" className="home-link2">
              Contact Us
            </a>
          </div>
        </header>
      </div>
      <div className="home-main">
        <img alt="image" src="/right.svg" className="home-left" />
        <img alt="image" src="/left.svg" className="home-left1" />
        <div className="home-hero">
          <img
            alt="image"
            src="/66671850486812.75543947processed-500h.jpg"
            className="home-image1"
          />
          <video
            src="https://secondcoat.s3.us-east-2.amazonaws.com/Second+Coat+Trailer.mp4"
            poster="https://secondcoat.s3.us-east-2.amazonaws.com/Second+Coat+Trailer.mp4"
            autoPlay="true"
            controls="true"
            className="home-video"
          ></video>
        </div>
      </div>
      <div className="home-banner">
        <h2 className="home-text02">
          Welcome to the adrenaline-fueled world of “Second Coat,” where vibrant
          colors collide, strategy reigns supreme, and every splatter counts. In
          this intense urban paintball game, you’ll need determination, focus,
          and a steady trigger finger.
        </h2>
      </div>
      <div className="home-features">
        <h2 id="roadmap" className="home-text03 Headline2">
          Public Roadmap
        </h2>
        <div className="home-features1">
          <FeatureCard
            text="Complete"
            imageSrc="/06.svg"
            cardTitle="Develop Backend Services"
            rootClassName="feature-card-root-class-name"
          ></FeatureCard>
        </div>
      </div>
      <FeatureCard
        text="Complete"
        imageSrc="/06.svg"
        cardTitle="Fine Tune Multiplayer Mechanics"
      ></FeatureCard>
      <FeatureCard
        text="Almost Complete"
        imageSrc="/06.svg"
        cardTitle="Complete Game Map"
      ></FeatureCard>
      <FeatureCard
        text="Coming Soon"
        imageSrc="/06.svg"
        cardTitle="Team Modes"
      ></FeatureCard>
      <FeatureCard
        text="Summer 2024"
        imageSrc="/06.svg"
        cardTitle="Testing"
      ></FeatureCard>
      <FeatureCard
        text="Fall 2024"
        imageSrc="/06.svg"
        cardTitle="Launch"
      ></FeatureCard>
      <div className="home-feature2">
        <div className="home-container1">
          <h2 id="aboutus" className="home-text04 Headline2">
            About Us
          </h2>
        </div>
        <span className="home-text05">Laura Zink - CEO</span>
        <span className="home-text06">
          <span>
            Laura Zink, CEO of NZ Game Studio, started her CPA career at one of
            the big four public
          </span>
          <br></br>
          <span>
            accounting firms as an auditor and then transitioned to financial
            reporting at a Fortune 500
          </span>
          <br></br>
          <span>
            company a few years later. Subsequently, she spent several years as
            a controller at two other
          </span>
          <br></br>
          <span>businesses.</span>
        </span>
        <span className="home-text14">Nolan Zink - Lead Developer</span>
        <span className="home-text15">
          <span>
            Nolan Zink, self-taught game developer, got his start creating
            mobile apps on the Android
          </span>
          <br></br>
          <span>
            platform at age 11. At age 12, his free Fidget Spinner app went
            viral and was downloaded over
          </span>
          <br></br>
          <span>
            500,000 times. With that success, he purchased his own computer and
            set out to learn hyper
          </span>
          <br></br>
          <span>
            casual game development on the iOS platform at age 13. During high
            school, his
          </span>
          <br></br>
          <span>
            focus has been independently learning C++ and C#, and developing a
            multiplayer game with
          </span>
          <br></br>
          <span>
            Unreal Engine. Now as a senior, he plans to publish Second Coat for
            the world to see. 
          </span>
        </span>
      </div>
      <div className="home-feature21">
        <div className="home-container2">
          <h2 id="contactus" className="home-text27 Headline2">
            Contact Us
          </h2>
        </div>
        <div className="home-container3">
          <span>Email us at </span>
          <a
            href="mailto:support@nzgamestudio.com?subject="
            className="home-link3"
          >
            support@nzgamestudio.com
          </a>
        </div>
      </div>
      <div className="home-footer">
        <footer className="home-container4">
          <img
            alt="image"
            src="/high%20resolution%20logo%20-%20cropped-200h.png"
            className="home-image2"
          />
          <div className="home-container5">
            <a href="#roadmap" className="home-link4">
              Roadmap
            </a>
            <a href="#aboutus" className="home-link5">
              <span className="home-text29">About Us</span>
              <br></br>
            </a>
            <a href="#contactus" className="home-link6">
              Contact Us
            </a>
            <a
              href="https://status.nzgamestudio.com"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link7"
            >
              Status
            </a>
            <a
              href="https://docs.google.com/document/d/18Ys9SjcPJ4FSU-5-b5myQsZogMyeZmdv/edit?usp=sharing&amp;ouid=113799623212361055459&amp;rtpof=true&amp;sd=true"
              target="_blank"
              rel="noreferrer noopener"
              className="home-link8"
            >
              Privacy Policy
            </a>
          </div>
          <div className="home-divider"></div>
          <div className="home-container6">
            <span className="home-text31 Body2">
              © 2024 NZ Game Studio LLC. All rights reserved
            </span>
          </div>
        </footer>
      </div>
    </div>
  )
}

export default Home
